<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Card-->
    <div class="card">
      <!--begin::Card header-->
      <div class="card-header border-0 pt-6">
        <!--begin::Card title-->
        <div class="card-title"></div>
        <!--begin::Card title-->
        <!--begin::Card toolbar-->
        <div class="card-toolbar">
          <!--begin::Toolbar-->
          <div
            class="d-flex justify-content-end"
            data-kt-user-table-toolbar="base"
          >
            <!--begin::Add user-->
            <router-link
              v-if="can('market_saiyut-store-create', 'all')"
              to="/apps/travels/market_saiyut/store/create"
            >
              <button type="button" class="btn btn-primary">
                <!--begin::Svg Icon | path: icons/duotone/Navigation/Plus.svg-->
                <span class="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <rect
                      fill="#000000"
                      x="4"
                      y="11"
                      width="16"
                      height="2"
                      rx="1"
                    />
                    <rect
                      fill="#000000"
                      opacity="0.5"
                      transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000)"
                      x="4"
                      y="11"
                      width="16"
                      height="2"
                      rx="1"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->เพิ่ม ร้าน
              </button>
            </router-link>
            <!--end::Add user-->
          </div>
          <!--end::Toolbar-->
        </div>
        <!--end::Card toolbar-->
      </div>
      <!--end::Card header-->
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table align-middle table-row-dashed fs-6 gy-5"
            id="kt_table_users"
            style="border-collapse: unset"
          >
            <!--begin::Table head-->
            <thead>
              <!--begin::Table row-->

              <tr
                class="
                  text-start text-gray-400
                  fw-bolder
                  fs-7
                  text-uppercase
                  gs-0
                "
              >
                <th class="min-w-50px">รูปภาพ</th>
                <th class="min-w-125px">ชื่อร้าน</th>
                <th class="min-w-125px">หมวดหมู่</th>
                <th class="min-w-125px">เบอร์โทร</th>
                <th class="min-w-125px">สถานะ</th>
                <th class="min-w-125px">เพิ่มโดย</th>

                <th class="text-end min-w-100px">จัดการ</th>
              </tr>
              <!--end::Table row-->
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody class="text-gray-600 fw-bold" v-if="stores.length > 0">
              <template v-for="store in stores" :key="store.id">
                <tr>
                  <td class="align-items-center">
                    <div class="symbol symbol-100px me-5">
                      <img :src="store.image" alt="" />
                    </div>
                  </td>
                  <td class="align-items-center">
                    {{ store.name }}
                  </td>
                  <td class="align-items-center">
                    {{ store.type }}
                  </td>
                  <td class="align-items-center">
                    {{ store.tel }}
                  </td>
                  <td class="align-items-center">
                    {{ store.status }}
                  </td>
                  <td class="align-items-center">
                    {{ store.id_user }}
                  </td>

                  <!--begin::Action=-->
                  <td class="text-end">
                    <a
                      href="#"
                      class="btn btn-light btn-active-light-primary btn-sm"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      data-kt-menu-flip="top-end"
                      >จัดการ
                      <!--begin::Svg Icon | path: icons/duotone/Navigation/Angle-down.svg-->
                      <span class="svg-icon svg-icon-5 m-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              transform="translate(12.000003, 11.999999) rotate(-180.000000) translate(-12.000003, -11.999999)"
                            />
                          </g>
                        </svg>
                      </span>
                      <!--end::Svg Icon--></a
                    >

                    <!--begin::Menu-->
                    <div
                      class="
                        menu
                        menu-sub
                        menu-sub-dropdown
                        menu-column
                        menu-rounded
                        menu-gray-600
                        menu-state-bg-light-primary
                        fw-bold
                        fs-7
                        w-125px
                        py-4
                      "
                      data-kt-menu="true"
                    >
                      <!--begin::Menu item-->
                      <div
                        v-if="can('market_saiyut-store-edit', 'all')"
                        class="menu-item px-3"
                      >
                        <router-link
                          :to="{
                            name: 'apps.travels.market_saiyut.store.edit',
                            params: { id: store.id },
                            props: { id: store.id },
                          }"
                        >
                          <a class="menu-link px-3">แก้ไข</a>
                        </router-link>
                      </div>
                      <!--end::Menu item-->
                      <!--begin::Menu item-->
                      <div
                        v-if="can('market_saiyut-store-delete', 'all')"
                        class="menu-item px-3"
                      >
                        <a
                          @click="delateStore(store.id)"
                          class="menu-link px-3"
                          >ลบ</a
                        >
                      </div>
                      <!--end::Menu item-->
                    </div>
                    <!--end::Menu-->
                  </td>
                  <!--end::Action=-->
                </tr>
                <!--end::Table row-->
              </template>
            </tbody>
            <!--end::Table body-->
            <tbody class="text-gray-600 fw-bold" v-else>
              <tr>
                <td colspan="6" class="text-center">ยังไม่มีข้อมูล</td>
              </tr>
            </tbody>
          </table>
          <!--end::Table-->
        </div>
         <Pagination v-bind:meta="meta" @pagination="updateHandler"></Pagination>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { defineComponent, onMounted, onUpdated, ref } from "vue";
import {
  MenuComponent,
  ToggleComponent,
  DrawerComponent,
} from "@/assets/js/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPage } from "@/core/helpers/checkpage";
import useStores from "@/core/services/api/travel/market_saiyut/store";
import useSweetalert from "@/core/helpers/sweetalert2";
import { useAbility } from "@casl/vue";
export default defineComponent({
  
  name: "market_saiyut-store",
 components: {
    Pagination,
  },
  setup() {
    const stores = ref([]);
    const meta = ref([]);
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    const { getStores, destroyStore } = useStores();
    const { Sconfirm, SToast, SconfirmNc } = useSweetalert();
    const { can } = useAbility();
    onMounted(() => {
      getStores()
        .then((response) => {
          stores.value = response.data.data;
          meta.value = response.data.meta;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
      checkPage("market_saiyut-store-list");
      setCurrentPageBreadcrumbs("ร้าน",["ส่งเสริมการท่องเที่ยว","ตลาดสายหยุด"]);
      DrawerComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
      MenuComponent.reinitialization();
    });

    function delateStore(id) {
      Sconfirm("ยืนยันการลบ", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          await destroyStore(id);
          await getStores()
          .then((response) => {
          stores.value = response.data.data;
          meta.value = response.data.meta;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
          isLoading.value = false;
          SToast("success", "ลบ ร้าน สำเร็จ");
        }
      });
    }
    async function updateHandler(e) {
      isLoading.value = true;
            getStores(e)
        .then((response) => {
          stores.value = response.data.data;
          meta.value = response.data.meta;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
    }
    return {
      can,
      stores,
      delateStore,
      updateHandler,
      meta,
      isLoading,
      color,
    };
  },
});
</script>

<style scoped></style>
